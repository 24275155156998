import React from 'react';
import { navigate } from 'gatsby';
import getSymbolFromCurrency from 'currency-symbol-map';

import Layout from '../../layouts';
import Container from '../../components/Container';

import '../../components/Checkout/complete.css';

import loadComponents from '../../components/Loadable';

const Button = loadComponents('button');
const SubpageHeader = loadComponents('subpage-header');

export default class CheckoutCompletePage extends React.Component {
  componentDidMount() {
    if (typeof window !== `undefined` && window.history.state === null) {
      navigate('/');
    }
  }

  render() {
    const page = { name: 'Order Complete', slug: 'checkout/complete' };
    const { location } = this.props;
    const prevPage = location.state && location.state.prevPage;

    const confirmation =
      typeof window !== `undefined` && window.history.state !== null
        ? window.history.state.confirmation
        : false;

    return (
      confirmation && (
        <Layout
          title={page.name}
          location={`/${page.slug}/`}
          prevPage={prevPage}
          padding="0 0 5.625rem"
        >
          <SubpageHeader title="Your application has been confirmed" />

          <Container className="checkoutComplete">
            <p>
              We have emailed a confirmation of your payment to:{' '}
              <strong>{confirmation.billing.email}</strong>
              .<br />
              Any updates will also be sent to this address.
            </p>
            <p>
              <strong style={{ color: `red` }}>IMPORTANT:</strong> If you do not
              receive your confirmation email, please check your email spam/junk
              box so you do not miss crucial updates or requests for
              information.
            </p>
            <ul>
              <li>
                <strong>Application Reference: </strong>
                <span>#{confirmation.id}</span>
              </li>
              <li>
                <strong>Billing Address: </strong>
                <span>
                  {confirmation.billing.address_1}, {confirmation.billing.city},{' '}
                  {confirmation.billing.state}, {confirmation.billing.postcode}
                </span>
              </li>
              <li>
                <strong>Total Paid: </strong>
                <span>
                  {getSymbolFromCurrency(confirmation.currency)}
                  {confirmation.total}
                </span>
              </li>
            </ul>
            <p>
              If you have any questions about your order email us at
              info@visa-applications.org with your reference number in the
              subject line.
            </p>
            <Button
              to="/"
              style={{ justifyContent: `center`, maxWidth: `300px` }}
              primary
            >
              Return to Homepage
            </Button>
          </Container>
        </Layout>
      )
    );
  }
}
